import React from 'react';
import SleepComparissionChart from './SleepComparissionChart';

const Sleep = () => {
    return( 
    <div className='middle-container mt p-1' >
       <h1 className='dark-txt regular bold'>Sleep</h1>
        <h3 className='dark-txt medium bold'>Sleep duration</h3>
             <SleepComparissionChart ></SleepComparissionChart>
    </div>
  );
};

export default Sleep;