import React from 'react';
import { Routes, Route, Navigate  }from 'react-router-dom';
import PatientsContainer from '../Patients/PatientsContainer';
import Notes from '../Patients/Notes';
import DetailedPredictors from '../Predictors/DetailedPredictors';
import Predictors from '../Predictors/Predictors';


const Container = () => {
  return (
        <div className='container'>
            <Routes>
                <Route path="/" element={<Navigate to="/predictors" />}/>

                {/* PATIENTS */}
                <Route path='/detailedpredictors' element={<DetailedPredictors/>}/>
                <Route path='/predictors' element={<Predictors/>}/>

                <Route path="/patient/:participantID" element={<PatientsContainer />} />
                <Route exact path="/patient/:participantID/notes" element={<Notes/>} />
            </Routes>
        </div>
  )
};
export default Container;