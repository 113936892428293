import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { fetchData } from '../Utilities/utils';
import NoteCreate from './NoteCreate';
const awsPredictorsInterfaceAPI = require('../../config.json').awsParticipantsEndpoints.awsPredictorsInterfaceAPI;
const participantsListUrl = require('../../config.json').awsParticipantsEndpoints.participantsList;
const notesUrl = require('../../config.json').awsParticipantsEndpoints.notes;

const dateToDisplayString = (timestamp) => {
    return timestamp ? new Date(parseInt(timestamp)).toLocaleDateString() : '-';
}

const Notes = () => {
  const { participantID } = useParams();
  const [notesInfo, setNotesInfo] = useState([]);
  const [seed, setSeed] = useState(1);

  useEffect(() => {
    async function defineParticipantEmail(){
      const notesInformation = await fetchData(`${awsPredictorsInterfaceAPI}/${participantsListUrl}/${participantID}/${notesUrl}`);
      
      setNotesInfo(notesInformation);
    };

    defineParticipantEmail();
  }, [participantID, seed])

  
  return (
    <div className="Notes">
      <article>
        <Link to={`/patient/${participantID}`}>
            <p className='main-txt small pointer'>&lt; Back to patient depression markers page</p>
        </Link>
      </article>

      <div style={{ textAlign: 'left', fontSize: '21px' }}>Notes </div>

      <div>
      {
        notesInfo.map((data,index) => {
          return(
            <div className="note" key={index}>
              <div className="note_head">
                <p className="note_date">{dateToDisplayString(data.timestamp)}</p>
                <div className="note_commands">
                  <p>{data.doctor}</p>
                </div>
              </div>
              <p className="note_content">{data.note}</p>
            </div>
          )
        })
      }
      </div>

      <NoteCreate reloadPage={setSeed}/>
    </div>
  )
};


export default Notes;