import React, {useEffect, useState} from 'react';
import { Link  }from 'react-router-dom';
import record from '../../Assets/Icons/record-icon.svg';
import { fetchData } from '../../components/Utilities/utils';
import Header from '../Header/Header';
const clinicianInterface = require('../../config.json').clinicianInterface.baseUrl;
const clinicianInterfaceParticipantDetailsInformation = require('../../config.json').clinicianInterface.participantDetailsInformation;

const testOverviewInfo = [
    {
        "participantID": "effe55d1-7256-4e9e-87a7-dbe3e0609a60",
        "email": "hugm022-faith@lst.tfo.upm.es",
        "hadsDepression": "Abnormal",
        "hadsAnxiety": "Abnormal",
        "hamdDepression": "No depression",
        "weightDeviation": 69,
        "waterDeviation": 72.25,
        "debqEmotionalEating": 18,
        "debqDiffuseEmotions": 6,
        "debqClearlyLabelledEmotions": 12,
        "debqExternalEating": 21,
        "deqbRestrainedEating": 25,
        "pgsga": "Low malnutrition risk",
        "ffqProteins": 408.74,
        "ffqFats": 18691.61,
        "ffqCarbs": 3224.7,
        "qlqc30GlobalHealthStatus": 83,
        "qlqc30PhysicalFunctioning": 100,
        "qlqc30RoleFunctioning": 100,
        "qlqc30EmotionalFunctioning": 100,
        "qlqc30CognitiveFunctioning": 83,
        "qlqc30SocialFunctioning": 100,
        "qlqc30Fatigue": 0,
        "qlqc30NauseaVomiting": 0,
        "qlqc30Pain": 17,
        "qlqc30Dyspnoea": 0,
        "qlqc30Insomnia": 33,
        "qlqc30AppetiteLoss": 0,
        "qlqc30Constipation": 0,
        "qlqc30Diarrhoea": 0,
        "qlqc30FinancialDifficulties": 0,
        "sleepRem": 18600,
        "sleepDeep": 23500,
        "sleepLight": 179880,
        "sleepAwake": 53160,
        "sleepNap": 0,
        "sleepInterruptions": 188,
        "psqi": 8,
        "activityDistance": 26632.86,
        "activitySteps": 41684.26
    },
    {
        "participantID": "371fcbee-a922-4dec-a926-373f9051fea2",
        "email": "cffaith010@gmail.com",
        "hadsDepression": "Mild depression",
        "hadsAnxiety": "Borderline abnormal",
        "hamdDepression": "No depression",
        "weightDeviation": 50,
        "waterDeviation": 72.25,
        "debqEmotionalEating": 18,
        "debqDiffuseEmotions": 6,
        "debqClearlyLabelledEmotions": 12,
        "debqExternalEating": 21,
        "deqbRestrainedEating": 25,
        "pgsga": "Low malnutrition risk",
        "ffqProteins": 408.74,
        "ffqFats": 18691.61,
        "ffqCarbs": 3224.7,
        "qlqc30GlobalHealthStatus": 83,
        "qlqc30PhysicalFunctioning": 100,
        "qlqc30RoleFunctioning": 100,
        "qlqc30EmotionalFunctioning": 100,
        "qlqc30CognitiveFunctioning": 83,
        "qlqc30SocialFunctioning": 100,
        "qlqc30Fatigue": 0,
        "qlqc30NauseaVomiting": 0,
        "qlqc30Pain": 17,
        "qlqc30Dyspnoea": 0,
        "qlqc30Insomnia": 33,
        "qlqc30AppetiteLoss": 0,
        "qlqc30Constipation": 0,
        "qlqc30Diarrhoea": 0,
        "qlqc30FinancialDifficulties": 0,
        "sleepRem": 18600,
        "sleepDeep": 23500,
        "sleepLight": 179880,
        "sleepAwake": 53160,
        "sleepNap": 0,
        "sleepInterruptions": 188,
        "psqi": 11,
        "activityDistance": 26632.86,
        "activitySteps": 41684.26
    },
    {
        "participantID": "371fcbee-a922-4dec-a926-373f9051fea2",
        "email": "cffaith010@gmail.com",
        "hadsDepression": "No depression",
        "hadsAnxiety": "Borderline abnormal",
        "hamdDepression": "No depression",
        "weightDeviation": 50,
        "waterDeviation": 72.25,
        "debqEmotionalEating": 18,
        "debqDiffuseEmotions": 6,
        "debqClearlyLabelledEmotions": 12,
        "debqExternalEating": 21,
        "deqbRestrainedEating": 25,
        "pgsga": "Low malnutrition risk",
        "ffqProteins": 408.74,
        "ffqFats": 18691.61,
        "ffqCarbs": 3224.7,
        "qlqc30GlobalHealthStatus": 83,
        "qlqc30PhysicalFunctioning": 100,
        "qlqc30RoleFunctioning": 100,
        "qlqc30EmotionalFunctioning": 100,
        "qlqc30CognitiveFunctioning": 83,
        "qlqc30SocialFunctioning": 100,
        "qlqc30Fatigue": 0,
        "qlqc30NauseaVomiting": 0,
        "qlqc30Pain": 17,
        "qlqc30Dyspnoea": 0,
        "qlqc30Insomnia": 33,
        "qlqc30AppetiteLoss": 0,
        "qlqc30Constipation": 0,
        "qlqc30Diarrhoea": 0,
        "qlqc30FinancialDifficulties": 0,
        "sleepRem": 18600,
        "sleepDeep": 23500,
        "sleepLight": 179880,
        "sleepAwake": 53160,
        "sleepNap": 0,
        "sleepInterruptions": 188,
        "psqi": 5,
        "activityDistance": 26632.86,
        "activitySteps": 41684.26
    }
]

const DetailedPredictors = () => {
    const [overviewParticipants, setOverviewParticipants] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // since there could be unk states, here are the codes:
        // red - alert; green - correct; grey - unk
        const analyseOverviewParticipantInformations = (participantInformation) => {
            const colors = {red: "red", green: "green", grey: "grey"}
            const a = [{email: "asdasd", participantID: "cd6829d9-8931-4680-b1a5-80a895f0d002", mentalState: colors.red, weightGain: colors.green, malnutrition: colors.grey, qualityLife: colors.green, voice: colors.green, activity: colors.red}]

            return a;
        }

        const getParticipants = async () => {
            if(!overviewParticipants){
                //const participantInformation = await fetchData(`${awsParticipantsClinitianInterfaceAPI}/${globalOverviewInformation}`);
                const participantInformation = testOverviewInfo;

                const overviewPatients = analyseOverviewParticipantInformations(participantInformation);
                setOverviewParticipants(overviewPatients);
                setLoading(false)
            }
        };
        
        getParticipants();
    }, [overviewParticipants])

  return (
    <div>
        <Header/>

        <div className='middle-container p-1'>

        <div className='table-intro flex-bet pt-2'>
            <h3 className='medium-bold-txt dark-blue-txt mb'>Detailed predictors overview</h3>
            <div className="flex-double-center">
                <div className='table-intro flex-center ps'>
                    <article className={`x-green-circle`}></article>
                    <div className='p-1'>OK</div>
                </div>
                <div className='table-intro flex-center ps'>
                    <article className={`x-red-circle`}></article>
                    <div className='p-1'>Alarm</div>
                </div>
                <div className='table-intro flex-center ps'>
                    <article className={`x-grey-circle`}></article>
                    <div className='p-1'>Unknown</div>
                </div>
            </div>
        </div>
        {
          !loading ? (
            <div className='table-container'>
              <table className='zui-table'>
                <thead>
                    <tr>
                        <th className='underline'>
                            <div>Email</div>
                        </th>
                        <th>
                            <div>Mental State</div>
                        </th>
                        <th>
                            <div>Weight Gain</div>
                        </th>
                        <th>
                            <div>Malnutrition</div>
                        </th>
                        <th>
                            <div>Quality Life</div>
                        </th>
                        <th>
                            <div>Voice</div>
                        </th>
                        <th>
                            <div>Activity</div>
                        </th>
                        <th>
                            <div>Trial Participantion</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                {
                    (
                        overviewParticipants.map((participantOverview,index) => {
                          return(
                            <tr className='x-small-txt dark-grey-txt' key={index}>
                                <td className='dark-blue-txt underline'>
                                    <Link to = {`/patient/${participantOverview.participantID}`} state={{email: participantOverview.email}} >
                                        {participantOverview.email}
                                    </Link>
                                </td>

                                <td className='main-txt'>
                                    <div className='flex-double-center'>
                                        <article className={`x-${participantOverview.mentalState}-circle`} ></article>
                                    </div>
                                </td>

                               <td className='main-txt'>
                                    <div className='flex-double-center'>
                                        <article className={`x-${participantOverview.weightGain}-circle`} ></article>
                                    </div>
                                </td>

                               <td className='main-txt'>
                                    <div className='flex-double-center'>
                                        <article className={`x-${participantOverview.malnutrition}-circle`} ></article>
                                    </div>
                                </td>

                               <td className='main-txt'>
                                    <div className='flex-double-center'>
                                        <article className={`x-${participantOverview.qualityLife}-circle`} ></article>
                                    </div>
                                </td>

                               <td className='main-txt'>
                                    <div className='flex-double-center'>
                                        <article className={`x-${participantOverview.voice}-circle`} ></article>
                                    </div>
                                </td>

                               <td className='main-txt'>
                                    <div className='flex-double-center'>
                                        <article className={`x-${participantOverview.activity}-circle`} ></article>
                                    </div>
                                </td>
                                
                                <td>
                                    <div className='light-grey-txt flex-center underline' style={{display: 'flex', justifyContent: 'center'}}>
                                        <a href={`${clinicianInterface}/${clinicianInterfaceParticipantDetailsInformation}/${participantOverview.participantID}`} target="_blank" rel="noreferrer">
                                            <img src={record} alt='record' className='icon-table'></img>
                                        </a>
                                    </div> 
                                </td>
                            </tr>
                          )
                        })
                    )
                }
                </tbody>
              </table>
            </div>
            ) :
              <div className='x-small-txt dark-grey-txt'>
                 <p className='dark-blue-txt underline' style={{position: 'relative', top: '10px'}}>
                     <b>Loading</b>
                 </p>
              </div>
            }
      </div>
    </div>
  )
};

export default DetailedPredictors;
