import React from 'react';
import QLQC30 from './QLQC30';
import { CancerTypes } from '../../../Assets/enums';
import QLQBR23 from './QLQBR23';
import QLQLC29 from './QLQLC29';
import Weight from './Weight';
import HADS from './HADS';
import DEBQ from './DEBQ';
import Water from './Water';
import PSQI from './PSQI';
import FFQ from './FFQ';

const PassiveMonitoring = (params) => {
    const {cancerType, country} = params;
    
    return(
    <div className='middle-container mt p-1' >
       <h1 className='dark-txt regular bold'>Passive monitoring</h1>
        <QLQC30 />

        {
           cancerType === CancerTypes.LUNG ?
               (<QLQLC29 />) :
               (<QLQBR23 />)
        }

        <DEBQ/>
        <FFQ country={country}/>
        <PSQI />
        <Weight />
        <Water />
        <HADS />
    </div>
  );
};

export default PassiveMonitoring;