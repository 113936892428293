import React from 'react';
import Sleep from '../Sleep/Sleep';
import Activity from '../Activity/Activity';

const ActiveMonitoring = (params) => {
    return( 
    <div className='middle-container mt p-1' >
        <h1 className='dark-txt regular bold'>Active monitoring</h1>
        
        <Sleep />
        
        <Activity />
    </div>
  );
};

export default ActiveMonitoring;