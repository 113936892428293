import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchData } from '../../Utilities/utils';
import Chart from './Utils/Chart';
const awsAPI = require('../../../config.json').awsParticipantsEndpoints;


const Weight = () => {
    const {participantID} = useParams();
    const [weightValues, setWeightValues] = useState([]);

    useEffect(() => {
        const fetchDataActivity = async () => {
            try {
                setWeightValues(await fetchData(`${awsAPI.awsPredictorsInterfaceAPI}/${awsAPI.participantsList}/${participantID}/${awsAPI.categoryInformation}/${awsAPI.participantCategory.weight}/${awsAPI.plotChartURL}`));
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        }

        fetchDataActivity();
    },[])

    return (
    <div>
      <div className='middle-container p-1'>
        <h4>Weight</h4>
        
        <Chart values={weightValues} yLabel='Weight (KG)'/>
      </div>
    </div>
    )
};

export default Weight;
