import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchData } from '../../Utilities/utils';
import Chart from './Utils/Chart';
const awsAPI = require('../../../config.json').awsParticipantsEndpoints;


const PSQI = () => {
    const {participantID} = useParams();
    const [psqiValues, setPSQIValues] = useState([]);

    useEffect(() => {
        const fetchPSQIInformationChart = async () => {
            try {
                setPSQIValues(await fetchData(`${awsAPI.awsPredictorsInterfaceAPI}/${awsAPI.participantsList}/${participantID}/${awsAPI.categoryInformation}/${awsAPI.participantCategory.psqi}/${awsAPI.plotChartURL}`));
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        }

        fetchPSQIInformationChart();
    },[])

    return (
    <div>
      <div className='middle-container p-1'>
        <h4>PSQI</h4>
        
        <Chart values={psqiValues} yLabel='Score'/>
      </div>
    </div>
    )
};

export default PSQI;
