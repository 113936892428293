import React from 'react';
import StepsChart from './StepsChart';

const Activity = () => {
    return( 
    <div className='middle-container mt p-1' >
       <h1 className='dark-txt regular bold'>Activity</h1>
        <h3 className='dark-txt medium bold'>Steps</h3>
             <StepsChart ></StepsChart>
    </div>
  );
};

export default Activity;