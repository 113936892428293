import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchData } from '../../Utilities/utils';
import DataTable from 'react-data-table-component';
const awsAPI = require('../../../config.json').awsParticipantsEndpoints;

const columns = [
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Proteins</div>,
        selector: row => <div>{row.proteins}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Carbohydrates</div>,
        selector: row => <div>{row.carbohydrates}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Polyunsaturated fats acids</div>,
        selector: row => <div>{row.polyunsaturated_fats_acids}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>b9 folic acid (ug)</div>,
        selector: row => <div>{row.b9_folic_acid}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>b12 cobalamin (ug)</div>,
        selector: row => <div>{row.b12_cobalamin}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>d calciferol (ug)</div>,
        selector: row => <div>{row.d_calciferol}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Magnesium (mg)</div>,
        selector: row => <div>{row.magnesium}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Zinc (mg)</div>,
        selector: row => <div>{row.zinc}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Iron (mg)</div>,
        selector: row => <div>{row.iron}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Date</div>,
        selector: row => <div>{row.date}</div>,
    }
];

const FFQ = ({country}) => {
    const {participantID} = useParams();
    const [questionniaresScore, setQuestionniaresScore] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDataFFQ = async () => {
            try {
                setQuestionniaresScore(await fetchData(`${awsAPI.awsPredictorsInterfaceAPI}/${awsAPI.participantsList}/${participantID}/${awsAPI.scores}/${awsAPI.participantCategory.ffq}/${country}`));
                setLoading(false)
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        }

        fetchDataFFQ();
    },[])

    return (
    <div>
      <div className='middle-container p-1'>
        <h4>FFQ Questionnaire</h4>
        
        <DataTable
            columns={columns}
            data={questionniaresScore}
            progressPending={loading}
            customStyles={customStyles}
        />
      </div>
    </div>
    )
};

export default FFQ;


const customStyles = {
  headCells: {
    style: {
      backgroundColor: '#DDEFEF',
      color: '#336B6B',
      textShadow: '1px 1px 1px #fff',
      justifyContent: 'center',
      fontWeight: '600',
      fontSize: '14px'
    }
  },
  noData: {
    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#336B6B',
      backgroundColor: '#DDEFEF',
    },
  },
  rows: {
        style: {
        },
    },
  cells: {
        style: {
            width:"145px",
            sortable: false,
        },
    },
};