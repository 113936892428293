import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchData } from '../../Utilities/utils';
import Chart from './Utils/Chart';
const awsAPI = require('../../../config.json').awsParticipantsEndpoints;


const Water = () => {
    const {participantID} = useParams();
    const [waterValues, setWaterValues] = useState([]);

    useEffect(() => {
        const fetchWaterData = async () => {
            try {
                setWaterValues(await fetchData(`${awsAPI.awsPredictorsInterfaceAPI}/${awsAPI.participantsList}/${participantID}/${awsAPI.categoryInformation}/${awsAPI.participantCategory.water}/${awsAPI.plotChartURL}`));
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        }

        fetchWaterData();
    },[])

    return (
    <div>
      <div className='middle-container p-1'>
        <h4>Water intake</h4>
        
        <Chart values={waterValues} yLabel='Water (L)'/>
      </div>
    </div>
    )
};

export default Water;
