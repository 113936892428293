import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import add_note from '../../Assets/Icons/add-note-icon.svg';
import styles from './Notes.css';         //Needs to be here in order to import this page's styles. In future, move these styles to App.css
import { fetchUserAttributes } from 'aws-amplify/auth';
import { postData } from '../Utilities/utils';
const awsPredictorsInterfaceAPI = require('../../config.json').awsParticipantsEndpoints.awsPredictorsInterfaceAPI;
const participantsListUrl = require('../../config.json').awsParticipantsEndpoints.participantsList;
const notesUrl = require('../../config.json').awsParticipantsEndpoints.notes;

const NoteCreate = ({reloadPage}) => {
    const { participantID } = useParams();

    const [formData, setFormData] = useState({
        doctor: '',
        note: '',
   });

    useEffect(() => {
        const User_Authentication_Info = async () => {
            const userAttributes = await fetchUserAttributes();
            setFormData(prevState => ({
                ...prevState,
                doctor: userAttributes.name
            }));
        }

        User_Authentication_Info();
  }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if(formData.note){          // is only stores the new note when the textbox has any text
            console.log(formData);

            setFormData({        // reset form
              doctor: '',
              note: '',
            });
     
            postData(`${awsPredictorsInterfaceAPI}/${participantsListUrl}/${participantID}/${notesUrl}`, formData, 'POST');
            console.log("TEST RELOAD")
            reloadPage(Math.random());
        }
    };

    return (
      <div className='add_note'>
        <form onSubmit={handleSubmit}>
            <div className="w-64 border border-gray-300 p-4 rounded" style={{flexDirection: 'row', display: 'flex', flex: 1}}>
                <div style={{flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-around'}}>
                    <div style={{}}>
                      <label htmlFor="note">Note:</label>
                    </div>
                    <textarea
                        id="note"
                        name="note"
                        value={formData.note}
                        onChange={handleChange}
                        className="w-full border border-gray-300 rounded p-2"
                        cols={80}
                    />
                </div>

                <button type="submit" className="font-bold">
                    <span>Add new</span>
                    <img src={add_note}/>
                </button>
            </div>
        </form>
      </div>
  )
};



export default NoteCreate;