import moment from 'moment';
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';

const Chart = (params) => {
    const values = params.values;
    const yLabel = params.yLabel;
    const category = params.category;

    return (
    <ResponsiveContainer width='100%' height={250}>
        <LineChart data={values} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="date"
            type="number"
            domain = {['auto', 'auto']}
            name = 'Time'
            tickFormatter={timeStr => moment.unix(timeStr).format('YYYY-MM')}
          >
            <Label value="Date" offset={-5} position="insideBottom" />
          </XAxis>
          <YAxis label={{ value: yLabel, angle: -90, position: 'insideLeft'}} style={{}}/>
          <Tooltip labelFormatter={() => ''} />
          <Line type="monotone" dataKey="value" stroke="#82ca9d" />
          {
            category && category==='HADS' && <ReferenceLine y={10} label={{value: "Depression", position: "top"}} stroke="red" />
          }
          {
            category && category==='PSQI' && <ReferenceLine y={21} label={{value: "Severe difficulties in all areas", position: "top"}} stroke="red" />
          }
        </LineChart>
    </ ResponsiveContainer>
    )
};

export default Chart;

