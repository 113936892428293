import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Label } from 'recharts';

import React from 'react';
import moment from 'moment';

const ActivityChart = (params) => {
  const values = params.values;

  return (
  <ResponsiveContainer width='100%' height={250}>
    <BarChart data={values} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
         <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="date"
            type="number"
            domain = {['auto', 'auto']}
            name = 'Time'
            tickFormatter={timeStr => moment.unix(timeStr).format('YYYY-MM')}
          >
            <Label value="Date" offset={-5} position="insideBottom" />
        </XAxis>
        <YAxis dataKey="value" type="number" label={{ value: 'Steps', angle: -90, position: 'insideLeft'}}/>
        <Tooltip labelFormatter={() => ''} />
        <Bar dataKey="value" stackId="a" name="Steps" fill="#8884d8" />
    </BarChart>
  </ ResponsiveContainer>
  );
};

export default ActivityChart;