import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchData } from '../../Utilities/utils';
import DataTable from 'react-data-table-component';
const awsAPI = require('../../../config.json').awsParticipantsEndpoints;

const columns = [
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Global health status</div>,
        selector: row => <div>{row.global_health_status_qol}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Physical functioning</div>,
        selector: row => <div>{row.physical_functioning}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Role functioning</div>,
        selector: row => <div>{row.role_functioning}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Emotional functioning</div>,
        selector: row => <div>{row.emotional_functioning}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Cognitive functioning</div>,
        selector: row => <div>{row.cognitive_functioning}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Social functioning</div>,
        selector: row => <div>{row.social_functioning}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Fatigue</div>,
        selector: row => <div>{row.fatigue}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Nausea and vomiting</div>,
        selector: row => <div>{row.nausea_and_vomiting}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Pain</div>,
        selector: row => <div>{row.pain}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Dyspnoea</div>,
        selector: row => <div>{row.dyspnoea}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Insomnia</div>,
        selector: row => <div>{row.insomnia}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Appetite loss</div>,
        selector: row => <div>{row.appetite_loss}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Constipation</div>,
        selector: row => <div>{row.constipation}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Diarrhoea</div>,
        selector: row => <div>{row.diarrhoea}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Financial difficulties</div>,
        selector: row => <div>{row.financial_difficulties}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Date</div>,
        selector: row => <div>{row.date}</div>,
    }
];

const QLQC30 = () => {
    const {participantID} = useParams();
    const [questionniaresScore, setQuestionniaresScore] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDataActivity = async () => {
            try {
                setQuestionniaresScore(await fetchData(`${awsAPI.awsPredictorsInterfaceAPI}/${awsAPI.participantsList}/${participantID}/${awsAPI.scores}/${awsAPI.participantCategory.qlqc30}`));
                setLoading(false)
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        }

        fetchDataActivity();
    },[])

    return (
    <div>
      <div className='middle-container p-1'>
        <h4>QLQ C30</h4>
        
        <DataTable
            columns={columns}
            data={questionniaresScore}
            progressPending={loading}
            customStyles={customStyles}
        />
      </div>
    </div>
    )
};

export default QLQC30;


const customStyles = {
  headCells: {
    style: {
      backgroundColor: '#DDEFEF',
      color: '#336B6B',
      textShadow: '1px 1px 1px #fff',
      justifyContent: 'center',
      fontWeight: '600',
      fontSize: '14px'
    }
  },
  noData: {
    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#336B6B',
      backgroundColor: '#DDEFEF',
    },
  },
  rows: {
        style: {
        },
    },
  cells: {
        style: {
            width:"120px",
            sortable: false,
        },
    },
};