import React, {useEffect, useState} from 'react';
import { Link, useParams }from 'react-router-dom';
import { fetchData } from '../Utilities/utils';
import { Button } from '@aws-amplify/ui-react';
import { CancerTypes, CategoryGeneralQuestionnairesTypes } from '../../Assets/enums';
import PassiveMonitoring from './Questionnaires/PassiveMonitoring';
import ActiveMonitoring from './Questionnaires/ActiveMonitoring';
const clinicianInterface = require('../../config.json').clinicianInterface.baseUrl;
const clinicianInterfaceParticipantDetailsInformation = require('../../config.json').clinicianInterface.participantDetailsInformation;

const awsPredictorsInterfaceAPI = require('../../config.json').awsParticipantsEndpoints.awsPredictorsInterfaceAPI;
const participantsListUrl = require('../../config.json').awsParticipantsEndpoints.participantsList;
const participantDetailsInformation = require('../../config.json').awsParticipantsEndpoints.participantDetailsInformation;

const PatientsContainer = () => {
  const {participantID} = useParams();
  const [participantInformation, setParticipantInformation] = useState([]);
  const [isPatientAvailable, setIsPatientAvailable] = useState(false);
  const [loading, setIsLoading] = useState(true);
  
  useEffect(() => {
    async function defineParticipantEmail(){
      setIsLoading(true);
      const participantInformation = await fetchData(`${awsPredictorsInterfaceAPI}/${participantsListUrl}/${participantID}/${participantDetailsInformation}`);
      setIsLoading(false)
      
      if (participantInformation && participantInformation.email){
        let generalQuestionnairesAttribution = CategoryGeneralQuestionnairesTypes;

        if (participantInformation.profile === CancerTypes.LUNG){
          generalQuestionnairesAttribution.push("QLQ-LC29");
        } else if (participantInformation.profile === CancerTypes.BREAST){
          generalQuestionnairesAttribution.push("QLQ-BR23");
        }

        setParticipantInformation(participantInformation);
        setIsPatientAvailable(true);
      }
    };

    defineParticipantEmail();
  }, [participantID])

  return (
    loading ?
    (
      <div className='mt'> 
        <p className='mt-3 bold'>Loading</p>
      </div>
    ) :
    (
      isPatientAvailable ?
        (
          <div className='mt'> 
            <article>
              <Link to='/'>
                  <p className='main-txt small pointer'>&lt; Back to patients predictors page</p>
              </Link>
            </article>
            
            <div className='flex-bet'>
                <article className='mb'>
                  <p className='medium-txt inline mr'>Patient </p>
                  <a href={`${clinicianInterface}/${clinicianInterfaceParticipantDetailsInformation}/${participantID}`} target="_blank" rel="noreferrer">
                       <p className='small bold inline'>{participantInformation.email}</p>
                  </a>
                  <br></br>
                   <p className='small-txt inline mr'>Trial started</p>
                  <p className='small-txt inline'>{participantInformation.StartDate}</p>
                </article>

                <article className='mb'>
                  <Link to = {`/patient/${participantID}/notes`} >
                    <Button style={{'background': 'white'}} size="small">Notes</Button>
                  </Link>
                </article>
            </div>

            <div>
                <p className='xx-large dark-blue-txt'>Depression Markers</p>
            </div>

            <div>
                <PassiveMonitoring cancerType={participantInformation.profile} country={participantInformation.nationality}/>
            
                <ActiveMonitoring />
            </div>

          </div>
        ) :
        (
          <div className='mt'> 
            <p className='mt-3 bold'>Patient information not availble</p>
          </div>
        )
    )
  )
};

export default PatientsContainer;
