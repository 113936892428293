// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `      .Notes {
        margin-top: 30px;
      }

      .note {
        margin-top: 30px;
      }

      .note_head {
        display: flex;
      }

      .note_date {
        display: flex;
        font-weight: bold;
      }

      .note_commands {
        margin-left: auto;
        margin-right: 0;
      }

      .note_commands button {
        display: inline-flex;
        margin-left: 15px;
        background: none;
      }

      .note_commands button:hover {
        border-bottom-style: solid;
      }

      .note_commands img {
        height: 16px;
        filter: invert(54%) sepia(0%) saturate(0%) hue-rotate(13deg) brightness(94%) contrast(89%);
        margin-right: 1px;
        align-self: center;
      }

      .add_note {
        margin-top: 30px;
      }

      .add_note button {
        margin-left: auto;
        margin-right: 0;
        background: none;
        font-weight: bold;
      }

      .add_note button:hover {
        border-bottom-style: solid;
      }

      .add_note img {
        height: 25px;
        filter: invert(0%) sepia(0%) saturate(7465%) hue-rotate(241deg) brightness(0%) contrast(101%);
        align-self: center;
      }`, "",{"version":3,"sources":["webpack://./src/components/Patients/Notes.css"],"names":[],"mappings":"MAAM;QACE,gBAAgB;MAClB;;MAEA;QACE,gBAAgB;MAClB;;MAEA;QACE,aAAa;MACf;;MAEA;QACE,aAAa;QACb,iBAAiB;MACnB;;MAEA;QACE,iBAAiB;QACjB,eAAe;MACjB;;MAEA;QACE,oBAAoB;QACpB,iBAAiB;QACjB,gBAAgB;MAClB;;MAEA;QACE,0BAA0B;MAC5B;;MAEA;QACE,YAAY;QACZ,0FAA0F;QAC1F,iBAAiB;QACjB,kBAAkB;MACpB;;MAEA;QACE,gBAAgB;MAClB;;MAEA;QACE,iBAAiB;QACjB,eAAe;QACf,gBAAgB;QAChB,iBAAiB;MACnB;;MAEA;QACE,0BAA0B;MAC5B;;MAEA;QACE,YAAY;QACZ,6FAA6F;QAC7F,kBAAkB;MACpB","sourcesContent":["      .Notes {\n        margin-top: 30px;\n      }\n\n      .note {\n        margin-top: 30px;\n      }\n\n      .note_head {\n        display: flex;\n      }\n\n      .note_date {\n        display: flex;\n        font-weight: bold;\n      }\n\n      .note_commands {\n        margin-left: auto;\n        margin-right: 0;\n      }\n\n      .note_commands button {\n        display: inline-flex;\n        margin-left: 15px;\n        background: none;\n      }\n\n      .note_commands button:hover {\n        border-bottom-style: solid;\n      }\n\n      .note_commands img {\n        height: 16px;\n        filter: invert(54%) sepia(0%) saturate(0%) hue-rotate(13deg) brightness(94%) contrast(89%);\n        margin-right: 1px;\n        align-self: center;\n      }\n\n      .add_note {\n        margin-top: 30px;\n      }\n\n      .add_note button {\n        margin-left: auto;\n        margin-right: 0;\n        background: none;\n        font-weight: bold;\n      }\n\n      .add_note button:hover {\n        border-bottom-style: solid;\n      }\n\n      .add_note img {\n        height: 25px;\n        filter: invert(0%) sepia(0%) saturate(7465%) hue-rotate(241deg) brightness(0%) contrast(101%);\n        align-self: center;\n      }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
