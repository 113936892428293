import moment from 'moment';
import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Legend,
} from 'recharts';

const Chart = ({values}) => {
  return (
    <ResponsiveContainer width='100%' height={250}>
        <LineChart data={values} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="date"
            type="number"
            domain = {['auto', 'auto']}
            name = 'Time'
            tickFormatter={timeStr => moment.unix(timeStr).format('YYYY-MM')}
          >
              <Label value="Date" offset={-5} position="insideBottom" />
          </XAxis>
          <YAxis label={{ value: 'Hours', angle: -90, position: 'insideLeft'}} style={{}}/>
          <Tooltip labelFormatter={() => ''} />
          <Legend layout="horizontal" verticalAlign="top" align="center"/>
          <Line type="monotone" dataKey="valueSleep" name="Sleep duration" stroke="#8884d8" dot={false}/>
          <Line type="monotone" dataKey="valueSleepInitialization" name="Bed time" stroke="#82ca9d" dot={false}/>
        </LineChart>
    </ ResponsiveContainer>
    )
};

export default Chart;

