import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchData } from '../../Utilities/utils';
import DataTable from 'react-data-table-component';
const awsAPI = require('../../../config.json').awsParticipantsEndpoints;

const columns = [
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Emotional eating score</div>,
        selector: row => <div>{row.emotional_eating_score}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Diffuse emotions score</div>,
        selector: row => <div>{row.diffuse_emotions_score}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Clearly labelled emotions score</div>,
        selector: row => <div>{row.clearly_labelled_emotions_score}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>External eating score</div>,
        selector: row => <div>{row.external_eating_score}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Restrained eating score</div>,
        selector: row => <div>{row.restrained_eating_score}</div>,
    },
    {
        center: "true",
        name: <div style={{textAlign: 'center'}}>Date</div>,
        selector: row => <div>{row.date}</div>,
    }
];

const DEBQ = () => {
    const {participantID} = useParams();
    const [questionniaresScore, setQuestionniaresScore] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchDataActivity = async () => {
            try {
                setQuestionniaresScore(await fetchData(`${awsAPI.awsPredictorsInterfaceAPI}/${awsAPI.participantsList}/${participantID}/${awsAPI.scores}/${awsAPI.participantCategory.debq}`));
                setLoading(false)
            } catch (error) {
                console.log('Error fetching data:', error);
            }
        }

        fetchDataActivity();
    },[])

    return (
    <div>
      <div className='middle-container p-1'>
        <h4>DEBQ Questionnaire</h4>
        
        <DataTable
            columns={columns}
            data={questionniaresScore}
            progressPending={loading}
            customStyles={customStyles}
        />
      </div>
    </div>
    )
};

export default DEBQ;


const customStyles = {
  headCells: {
    style: {
      backgroundColor: '#DDEFEF',
      color: '#336B6B',
      textShadow: '1px 1px 1px #fff',
      justifyContent: 'center',
      fontWeight: '600',
      fontSize: '14px'
    }
  },
  noData: {
    style: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#336B6B',
      backgroundColor: '#DDEFEF',
    },
  },
  rows: {
        style: {
        },
    },
  cells: {
        style: {
            width:"120px",
            sortable: false,
        },
    },
};