import React, { useEffect, useState } from "react";
import { fetchData } from "../../Utilities/utils";
import { useParams } from "react-router-dom";
import SleepChart from "../Questionnaires/Utils/SleepChart";
const awsAPI = require('../../../config.json').awsParticipantsEndpoints;

const SleepComparissionChart = () => {
  const { participantID } = useParams();
  const [dataGraph, setDataGraph] = useState();

  useEffect(() => {
    const fetchDataActivity = async () => {
      try {
        setDataGraph(await fetchData(`${awsAPI.awsPredictorsInterfaceAPI}/${awsAPI.participantsList}/${participantID}/${awsAPI.categoryInformation}/${awsAPI.participantCategory.SleepCompare}/${awsAPI.plotChartURL}`));
      } catch (error) {
          console.log('Error fetching data:', error);
      }
    }

    fetchDataActivity();
  },[])

  return (
    <div>
      <SleepChart values={dataGraph}/>
    </div>
  )
}

export default SleepComparissionChart;